import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import {Endpoints} from './Constants';

import {toast} from "react-toastify";
import {format, parseISO} from 'date-fns'
import axios from "./axiosConfig";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';



const CompanyRequests = () => {
    const location = useLocation();
    const [page, setPage] = useState(0);
    const [toggle, setToggle] = useState(false);
    const [data, setData] = useState({requests:[],meta : {totalPages:0, totalElements:0, page:0,size:25}});
    let pagesize = 15;

    useEffect(() => {
        const getRequests =async ()=> {
            const response = await axios.get(`${Endpoints.PRODUCT_REQUESTS}/own/?` + qs.stringify({ page:page, pagesize:pagesize }, {skipNull:true}));
            setData(response.data);
        }
        getRequests();
    }, [ toggle ]);


    const handlePageClick = (data) => {

        setPage(data.selected);
        setToggle(!toggle);

    };

    const handleDelete = (id) => {
        confirmAlert({
            title: 'Устгах',
            message: 'Та устгахдаа итгэлтэй байна уу?',
            buttons: [
                {
                    label: 'Тийм',
                    onClick: () => {
                        async function removeRequest() {
                            const response = await axios.delete(`${Endpoints.PRODUCT_REQUEST}/${id}`);
                            toast.success("Амжилттай устгалаа!");
                            //setData(response.data);
                        }
                        removeRequest();

                    }
                },
                {
                    label: 'Үгүй',
                    onClick: () => {}
                }
            ]
        });

    };


    const getStatus = (stat) => {
        if (stat === "SENT") {
            return "ИЛГЭЭСЭН";
        } else if (stat === "DENIED") {
            return "БУЦСАН";
        } else if (stat === "ACTIVATED") {
            return "ИДЭВХТЭЙ";
        }
    }

    let counter = Number(data.meta.page) * Number(data.meta.size);
    const items = data.requests.map(
        (request) => {
            counter++;
            return (
                <tr key={request.id}>
                    <td className="border px-8 py-4"> <Link to={`/request/${request.id}`}>
                        {counter}
                    </Link>
                    </td>
                    <td className="border px-8 py-4">
                        <Link className="text-blue-500 underline hover:cursor-pointer" to={`/request/${request.id}`}>
                            {request.name}
                        </Link>
                    </td>
                    <td className="border px-8 py-4">{request.packaging} - {request.packaging_unit}</td>
                    <td className="border px-8 py-4">{request.created &&
                        format(parseISO(request.created), "yyyy-MM-dd")
                        // dayjs.utc(applications[id].created).format("MM/DD/YYYY")
                    }</td>
                    <td className="border px-8 py-4">{getStatus(request.status)}</td>
                    <td className="border px-8 py-4"><a className="delete" onClick={() => handleDelete(request.id)}></a></td>
                </tr>
            )
        });

    return (
        <div className="m-4">
            <div className="flex justify-end my-4">
                <Link className="text-blue-500 underline" to="/image-requests">Зураг оруулах хүсэлтүүд</Link>
            </div>

            <table className="w-full shadow-lg bg-white border-collapse">
                <thead>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">#</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Нэр</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Савлалтын хэмжээ /нэгж, багц/</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Илгээсэн</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Статус</th>
                    <th className="bg-blue-100 border text-left px-8 py-4"></th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <nav className="my-2">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={Number(page)}
                               breakLabel={"..."}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={data.meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeClassName="bg-blue-300 hover:bg-blue-300 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                               pageClassName="text-gray-500 px-3 py-2 leading-tight border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                               pageLinkClassName=""
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={""}
                               previousLinkClassName="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                               nextClassName={""}
                               nextLinkClassName="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                               containerClassName="inline-flex items-center -space-x-px"
                               forcePage={Number(page)}
                />
            </nav>
            <br/><br/>

        </div>
    )

}


export default CompanyRequests


