/**
 * Created by gantushig on 4/16/17.
 */

import React, {useState} from "react";
import juram from "./images/GLN-SSCC-001.jpg"
import {useNavigate, useParams} from "react-router-dom";

const ProductApplCreateQuestion = () => {

    let {tp} = useParams();
    const [agree, setAgree] = useState(false);
    const navigate = useNavigate();
    if (tp === undefined) {
        console.log(" tp undefined");
        navigate("/request-create/");
        //product.barcode_type = GTIN;

    } else if (tp === 'GTIN') {
        navigate("/request-create");
    }


    const handleChangeAgree = (e) => {
        setAgree(e.target.checked)
    };
    const handleSubmit = (e) => {
        navigate("/request-create/" + tp + "?");
    };

    return (

        <div className="m4">

            <div className="row">
                <div className="column">

                    <img className='img-fluid shadow-4'
                         style={{paddingTop: "25px", paddingLeft: "10px", paddingRight: "10px"}}
                         src={juram}
                    />

                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label"></label>
                        </div>
                        <div className="field-body" style={{margin: "25px"}}>
                            <div className="field">
                                <div className="control">
                                    <label className="checkbox">
                                        <input className="checkbox" type="checkbox" checked={agree}
                                               style={{marginRight: "8px"}}
                                               onChange={(e) => handleChangeAgree(e)}/></label>

                                    <span style={{
                                        color: "#002c6c",
                                        fontWeight: 500,
                                        fontSize: "18px",
                                    }}>Би мэдээллийг уншиж танилцаад, зөвшөөрч байна.</span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                         style={{paddingTop: '10px', marginLeft: "25px", marginBottom: "25px"}}>
                        <button className="border p-2 bg-orange-500 text-white rounded-md disabled:bg-orange-100" disabled={!agree}
                                onClick={(e) => handleSubmit(e)}>
                            ДАРААХ
                        </button>
                    </div>

                </div>
            </div>
        </div>

    )


}


export default ProductApplCreateQuestion