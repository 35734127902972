import React, {useEffect, useState} from "react";
import {Endpoints} from "./Constants";
import {useNavigate} from "react-router-dom";
import axios from "./axiosConfig";
import {toast} from "react-toastify";
import CompanyViewComponent from "./components/CompanyViewComponent";
import axios2 from "./axiosConfig2";
import FileDownload from "js-file-download";

const CompanyView = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({company: {}, contacts: []});
    useEffect(() => {
        async function getCompany() {
            const response = await axios.get(`${Endpoints.COMPANY}/own`);
            if (response.data.invoices.length > 0) {
                toast.success("Нэхэмжлэх ирсэн байна!");
                navigate("/invoices", {state: {invoices: response.data.invoices}})
            }
            setData(response.data);
        }

        getCompany();

    }, []);

    const handleDownloadDoc = (s) => {
        async function downloadDoc() {
            let config = {};
            config["url"] = `${Endpoints.COMPANY}/${data.company.id}/doc/${s}`;
            config["responseType"] = "arraybuffer";

            let response = await axios2(config);
            const fileNameHeader = "x-suggested-filename";
            const suggestedFileName = response.headers[fileNameHeader]
            let fn = decodeURIComponent(suggestedFileName);

            FileDownload(response.data, fn)
        }
        downloadDoc();
    };

    return (
        <CompanyViewComponent company={data.company} contacts={data.contacts} onDownloadDoc={handleDownloadDoc}/>
    );
}


export default CompanyView




