import {Link} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import {Endpoints} from './Constants';
import axios from './axiosConfig'
import {format, parseISO} from 'date-fns'
import FileSaver from "file-saver";
import debounce from 'lodash.debounce';

const CompanyProducts = () => {
    const [data, setData] = useState({products:[],meta : {totalPages:0, totalElements:0, page:0,size:25}});
    const [filters, setFilters] = useState({ name: '', barcode: '', page: 0, pagesize: 25, orderField: "id", isAsc: true });
    const [toggleSearch, setToggleSearch] = useState(false);

    const getProducts = async() => {
        const url = `${Endpoints.PRODUCTS}/own/?` + qs.stringify({ ...filters, search_start: true }, { skipNull: true });
        const response = await axios.get(url);
        setData(response.data);
    };

     useEffect(() => {
         // const getProducts = async() => {
         //     const url = `${Endpoints.PRODUCTS}/own/?` + qs.stringify({ ...filters, search_start: true }, { skipNull: true });
         //     const response = await axios.get(url);
         //     setData(response.data);
         // }
         getProducts();
     }, [ toggleSearch]);

    const debouncedSetFilters = useCallback(
        debounce((name, value) => {
            setFilters((prev) => ({ ...prev, [name]: value, page: 0 }));
        }, 300),
        []
    );

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     debouncedSetFilters(name, value);
    // };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value, page: 0 }));
    };
    //
    // const debouncedHandleInputChange = debounce(handleInputChange, 300);
    // const handleDebouncedInputChange = useCallback((e) => {
    //     debouncedHandleInputChange(e);
    // }, [debouncedHandleInputChange]);

    // const debouncedHandleInputChange = useMemo(() => debounce(handleInputChange, 300), []);
    //
    // const handleDebouncedInputChange = (e) => {
    //     e.persist();
    //     debouncedHandleInputChange(e.target.name, e.target.value);
    // };
    const handleSearch = (e) => {
        e.preventDefault();
        setFilters((prev) => ({ ...prev, page: 0, orderField: "id", isAsc: true }));
        //getProducts();
        setToggleSearch(!toggleSearch)
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSearch(e);
        }
    };
    const handleExcel = async (e) => {
        e.preventDefault();
        try {
            const config = {
                headers: { "Accept": "application/json", "Content-Type": "application/json" },
                url: `${Endpoints.PRODUCTS}/own/print/?name=${filters.name}&barcode=${filters.barcode}`,
                responseType: "arraybuffer"
            };
            const response = await axios(config);
            const blob = new Blob([new Uint8Array(response.data)], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            FileSaver.saveAs(blob, `products-${Date.now()}.xlsx`);
        } catch (error) {
            console.error("Error exporting to Excel", error);
        }

    };
    const handlePageClick = ({ selected }) => {
        // Use the callback form of useState to ensure we get the most recent state
        // setFilters(prev => {
        //     const newFilters = { ...prev, page: selected };
        //     getProducts(newFilters); // Use the new state directly here
        //     return newFilters;
        // });
         setFilters((prev) => ({ ...prev, page: selected }));
        setToggleSearch(!toggleSearch)
        // getProducts();
    };

    const handleOrderClick = (field) => {
        setFilters((prev) => ({
            ...prev,
            orderField: field,
            isAsc: prev.orderField === field ? !prev.isAsc : true
        }));
    };
    let counter = filters.page * filters.pagesize;
    //let counter = Number(data.meta.page) * Number(data.meta.size);
    const items = data.products.map((p,i) => {
            return (
                <tr key={p.id}>
                    <td className="border px-8 py-4">{counter + i + 1}</td>
                    <td className="border px-8 py-4">
                        <Link className="text-blue-500 underline hover:cursor-pointer" to={`/product/${p.id}`}>
                            {p.name}
                        </Link>
                    </td>
                    <td className="border px-8 py-4">                            {p.brand_name}                    </td>
                    <td className="border px-8 py-4">
                        <Link className="text-blue-500 underline hover:cursor-pointer" to={`/product/${p.id}`}>
                            {p.barcode}
                        </Link>
                    </td>
                    <td className="border px-8 py-4">{p.packaging} {p.packaging_unit}</td>
                    <td className="border px-8 py-4">{p.created && format(parseISO(p.created), "yyyy-MM-dd")}</td>
                </tr>
            )
        });

    return (
        <div className="m-4">
            <ProductsSearchPanel
                name={filters.name} barcode={filters.barcode}
                onChange={handleInputChange}
                onClickSearch={handleSearch}
                onClickExcel={handleExcel}
                onKeyPress={handleKeyPress}
            />

            <table className="w-full shadow-lg bg-white border-collapse">
                <thead>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">#</th>
                    <th className="bg-blue-100 border text-left px-8 py-4" onClick={() => handleOrderClick("name")}>
                        <div  className="text-blue-500 underline hover:cursor-pointer">Нэр</div>
                    </th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Бренд Нэр</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">
                        <a className="text-blue-500 underline hover:cursor-pointer" onClick={(e)=>handleOrderClick("barcode")}>Баркод/GTIN</a>
                    </th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Савлалтын хэмжээ /нэгж, багц/</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Үүсгэсэн</th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <nav className="my-2">
                <ReactPaginate
                    previousLabel={"Өмнөх"} nextLabel={"Дараах"} initialPage={filters.page}
                    breakLabel={<span>...</span>} breakClassName={"pagination-ellipsis"}
                    pageCount={data.meta.totalPages} marginPagesDisplayed={2} pageRangeDisplayed={5}
                    activeClassName="bg-blue-300 hover:bg-blue-300 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                    pageClassName="text-gray-500 px-3 py-2 leading-tight border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    onPageChange={handlePageClick} containerClassName="inline-flex items-center -space-x-px"
                    previousLinkClassName="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    nextClassName={""}
                    nextLinkClassName="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                />
            </nav>
            <br/><br/>
        </div>
    )

}

const ProductsSearchPanel = ({ name, barcode, onChange, onClickSearch, onClickExcel, onKeyPress }) => (
    <div>
        <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Нэр</label>
            <input name="name" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={name || ''} onChange={onChange} onKeyPress={onKeyPress} placeholder="Оруулах" />
        </div>
        <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">Barcode</label>
            <input name="barcode" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={barcode || ''} onChange={onChange} onKeyPress={onKeyPress} placeholder="Оруулах" />
        </div>
        <div className="flex gap-2 justify-start my-2">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={onClickSearch}>Хайх</button>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={onClickExcel}>Excel</button>
        </div>
    </div>
);

export default CompanyProducts


