/**
 * Created by gantushig on 11/6/15.
 */

import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";

import {Endpoints} from "./Constants";
import {format, parseISO} from 'date-fns'
import axios from "./axiosConfig";
import {toast} from "react-toastify";


const CompanyGcps = () => {

    const navigate = useNavigate();
    const [data, setData] = useState({company: {}, contacts: [], gcps:[]});
    useEffect(() => {
        async function getCompany() {
            const response = await axios.get(`${Endpoints.COMPANY}/own`);
            if (response.data.invoices.length > 0) {
                toast.success("Нэхэмжлэх ирсэн байна!");
                navigate("/invoices", {state: {invoices: response.data.invoices}})
            }
            setData(response.data);
        }

        getCompany();

    }, []);

    let counter = 0;
    const items = data.gcps.map(
        (gcp) => {
            counter++;
            return (
                <tr key={gcp.id}>
                    <td className="border px-8 py-4">
                        {counter}
                    </td>
                    <td className="border px-8 py-4">
                        {gcp.prefix}
                    </td>
                    <td className="border px-8 py-4">{"GCP" + gcp.size}</td>
                    <td className="border px-8 py-4">{(gcp.issued === null) ? "" : format(parseISO(gcp.issued), "yyyy-MM-dd")}
                        {/*dayjs.utc(gcps[id].issued).format("YYYY-MM-DD")*/}
                    </td>
                    <td className="border px-8 py-4">
                        <Link className="text-blue-500 underline" to={`/gcp/${gcp.id}/barcodes`}>
                            Файлууд
                        </Link>
                    </td>
                    <td className="border px-8 py-4">
                        <Link className="text-blue-500 underline" to={`/gcp/${gcp.id}/payments`}>
                            Үзэх
                        </Link>
                    </td>
                    <td className="border px-8 py-4">
                        <Link className="text-blue-500 underline" to={`/gcp/${gcp.id}/invoices`}>
                            Үзэх
                        </Link>
                    </td>

                </tr>
            )
        });

    return (
        <div className="m-4">
            {/*<h2>GCP</h2>*/}
            <table className="w-full shadow-lg bg-white border-collapse">
                <thead>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">#</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">GCP</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Төрөл</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Олгосон</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Баркод</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Төлбөр</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Нэхэмжлэх</th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>


        </div>
    )

}



export default CompanyGcps

