/**
 * Created by gantushig on 11/6/15.
 */

import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";

import {Endpoints} from "./Constants";
import {format, isValid, parseISO} from 'date-fns'
import axios from "./axiosConfig";


const CompanyPayments = () => {
    //{token, payments, gcpId, loadPayments, payments_ids_by_order}
    let {gcpId} = useParams();
    const [data, setData] = useState({payments:[]});

    useEffect(() => {
        async function getPayments() {
            const response = await axios.get(`${Endpoints.GCP}/${gcpId}/payments`);
            setData(response.data);
        }
        getPayments();

    }, []);

    const getStatus = (paid) => {
        if (paid) {
            return "Төлсөн"
        } else {
            return "Төлөөгүй"
        }
    }
    function isValidDate(d) {
        return !Number.isNaN(new Date(d).getTime());
        //return d instanceof Date && !isNaN(d);
    }

     const getDate = (d) =>{
        if(isValid(d)) {
            let dt = new Date(d);
            return (dt.getFullYear()+"-"+(('00'+(dt.getMonth()+1)).slice(-2))+"-"+('00'+(dt.getDate())).slice(-2));
        } else {
            return "-"
        }

    }
let counter = 0;
    const items = data.payments.map(
        (payment) => {
            counter++;
            return (
                <tr key={payment.year}>
                    <td className="border px-8 py-4">{counter}</td>
                    <td className="border px-8 py-4">{payment.year}</td>
                    <td className="border px-8 py-4">{getDate(payment.paid_date)}
                    </td>
                    <td className="border px-8 py-4"><span style={{color:payment.paid?'green':'red'}}>{getStatus(payment.paid)}</span></td>
                </tr>
            )
        });

    return (
        <div className="m-4">
            <h2 className="text-2xl font-bold">Төлбөр</h2>
            <br/>
            <table className="w-full shadow-lg bg-white border-collapse">
                <thead>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">#</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Жил</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Огноо</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Статус</th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <br/><br/>

        </div>
    )

}

export default CompanyPayments

