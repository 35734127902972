/**
 * Created by gantushig on 11/6/15.
 */

import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";

import {Endpoints} from "./Constants";
import {format, parseISO} from 'date-fns'
import axios from "./axiosConfig";
import axios2 from "./axiosConfig2";
import FileDownload from "js-file-download";


const CompanyInvoices = () => {
    //{token, invoices, gcpId, loadInvoices, invoices_ids_by_order, print}
    let {gcpId} = useParams();
    const [data, setData] = useState({invoices:[]});

    useEffect(() => {
        const getInvoices = async () => {
            const response = await axios.get(`${Endpoints.GCP}/${gcpId}/invoices`);
            setData(response.data);
        }
        getInvoices();
    }, []);

    const handlePrint = (id) => {
        console.log('print invoice');

        async function print1() {
            let config = {};
            config["url"] = `${Endpoints.INVOICE_ADMIN}/${id}/print`;
            config["responseType"] = "arraybuffer";

            let response = await axios2(config)
            const fileNameHeader = "x-suggested-filename";
            const suggestedFileName = response.headers[fileNameHeader]
            let fn = decodeURIComponent(suggestedFileName);

            FileDownload(response.data, fn)
        }
        print1();

        //print(token, id)
    };

    let counter = 0;
    const items = data.invoices.map(
        (invoice) => {
            counter++;
            return (
                <tr key={invoice.id}>
                    <td className="border px-8 py-4">{counter}</td>
                    <td className="border px-8 py-4"><Link to={`/gcp/${gcpId}/invoice/${invoice.id}`}>
                        {invoice.label}</Link>
                    </td>
                    <td className="border px-8 py-4">{invoice.total_amount}</td>
                    <td className="border px-8 py-4">{ invoice.created && format(parseISO(invoice.created), "yyyy-MM-dd")}
                </td>
                </tr>
            )
        });

    return (
        <div className="m-4">
            <h2 className="text-2xl font-bold">Нэхэмжлэх</h2>
            <br/>
            <table className="w-full shadow-lg bg-white border-collapse">
                <thead>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">#</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Утга</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Дүн</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Үүсгэсэн</th>

                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <br/><br/>

        </div>
    )
}

export default CompanyInvoices;


